/*==================================================================*/
/*                                                                  */
/*  EDER GUIMARAES RODRIGUES - 25/03/2022                           */
/*  HERBERT ISHIMURA SOUSA - 21/07/2022                             */
/*------------------------------------------------------------------*/
/*  ROUTER.TSX - ARQUIVO DE ROTAS DO DRIPIX                         */
/*                                                                  */
/*------------------------------------------------------------------*/
/*  CSS GERAL...............: LINHA: 020                            */
/*  CSS COMPONENTE NAVBAR...: LINHA: 079                            */
/*  CSS COMPONENTE CADBAR...: LINHA: 099                            */
/*  CSS COMPONENTE CAROUSEL.: LINHA: 123                            */
/*  CSS PAGINA MENU.........: LINHA: 147                            */
/*  CSS PAGINA HOME.........: LINHA: 191                            */
/*  CSS PAGINA CADASTRO.....: LINHA: 297                            */
/*  CSS PAGINA TERMO........: LINHA: 458                            */
/*  CSS PAGINA CONTATO......: LINHA: 487                            */
/*  CSS COMPONENTE FOOTER...: LINHA: 555                            */
/*==================================================================*/
/*                                                                  */
/*  CSS GERAL                                                       */
/*                                                                  */
/*------------------------------------------------------------------*/
/**/


* {
    margin: 0;
    padding: 0; 
    outline: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
    text-decoration: none;
    color: inherit;
    text-align: center;

}
.viewport {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
.app {
    width: 30%;
    height: 92%;
    border: 1px solid #818181;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #111;   
    animation: slide-in 0.5s cubic-bezier(
        0.250, 0.460, 0.450, 0.940
    ) both;
}
@media (max-width: 499px) { .app { width: 100%; height: 100%; }}
@media (min-width: 500px) and (max-width: 768px) { .app { width: 70%; height: 90%; }}
@media (min-width: 769px) and (max-width: 1024px) { .app { width: 50%; }}
@media (min-width: 1025px) and (max-width: 1440px) { .app { width: 40%; }}
@media (min-width: 1441px) { .app { width: 30%; }}
.display__none {
    display: none;
}
.align_center {
    text-align: center;
}
::-webkit-scrollbar {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 5px;
    background: #cccccc; 
}
::-webkit-scrollbar-thumb {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #818181; 
}
@keyframes slide-in { 0% { opacity: 0; } 100% { opacity: 1; } }
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS COMPONENTE NAVBAR                                           */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.navbar__area {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #F8F8F8;
}
.navbar_bars {
    position: absolute;
    width: 30px;
    left: 30px;
}
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS COMPONENTE CADBAR                                           */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.cadbar__area {
    height: 50px;
    background-color: #FF6900;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cadbar__area img {
    width: 20px;
    margin-right: 10px;
    filter: invert(100%) sepia(0%) saturate(7486%) hue-rotate(326deg) brightness(106%) contrast(106%);
}
.cadbar__area p {
    color: #FFF;
    font-weight: 700;
    font-size: 12pt;
}
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS COMPONENTE CAROUSEL                                         */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.carousel__page1, .carousel__page2 {  
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr; 
}
.carousel__page1 div, .carousel__page2 div {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.carousel__page1 img, .carousel__page2 img {
    width: 100%;
}
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS PAGINA MENU                                                 */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.menu__logo {
    height: 100px;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu__backbar {
    height: 50px;
    background-color: #ECECEC;
    display: flex;
    align-items: center;
    padding-left: 30px;
}
.menu__backbar img {
    width: 20px;
    margin-right: 10px;
}
.menu__backbar p {
    font-weight: 700;
    font-size: 12pt;
}
.menu__links {
    padding: 35px 25px;
}
.menu__links p {
    color: #FF6900;
    font-weight: 700;
    font-size: 15pt;
    padding: 10px 0;
}
.menu__links hr {
    border: 1px solid #ECECEC;
}

.menu__pointer {
    cursor: pointer;
}

/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS PAGINA HOME                                                 */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.home__how_to {
    padding: 35px 25px;
}

.how_to__title {
    font-weight: 700;
    font-size: 15pt;
    color: #FF6900;
    text-align: center;
    margin: 40px;
}
.how_to__texts p:nth-child(1),
.how_to__texts p:nth-child(4) {
    font-weight: 700;
    font-size: 15pt;
    color: #FF6900;
}
.how_to__how, .how_to__pay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.how_to__how p:nth-child(1), .how_to__pay p:nth-child(1) {
    font-weight: 400;
    font-size: 10pt;
    color: #FF6900;
    padding-bottom: 8px;
}
.how_to__how p:nth-child(2), .how_to__pay p:nth-child(2) {
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 8px;
    color: #818181;
}
.how_to__how p:nth-child(3), .how_to__pay p:nth-child(3), .how_to__texts p:nth-child(2) {
    font-weight: 400;
    font-size: 10pt;
    padding-bottom: 18px;
    color: #818181;
}

.home__benefits {
    background-color: #F8F8F8;
    padding: 35px 25px;
    border-radius: 40px;
    margin: 10px;
}
.benefits__texts p:nth-child(1) {
    font-weight: 700;
    font-size: 15pt;
    color: #FF6900;
}
.benefits__texts p:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 3;
    font-weight: 700;
    font-size: 10pt;
    color: #FF6900;
    padding-bottom: 10px;
}
.benefits__texts p:nth-child(3), .benefits__texts p:nth-child(4),
.faq__texts p:nth-child(3), .faq__texts p:nth-child(5), 
.faq__texts p:nth-child(7) {
    font-weight: 400;
    font-size: 10pt;
    padding-bottom: 18px;
    color: #818181;
} 
.benefits__imgs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.benefits__imgs div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 150px;
    background-color: #FFF;
    border-radius: 20px;
    margin: 10px;
}

.home__oficiais {
    
    padding: 10px 10px;
    margin: 10px;
}

.oficiais_title{ 
    font-weight: 700;
    font-size: 15pt;
    color: #FF6900;
    padding-bottom: 10px;
}

.oficiais_text_lojas{
    font-weight: 400;
    font-size: 9.5pt;
    color: #818181;
    padding-bottom: 15px;
}

.shopping{
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 8px;
    color: #ff6900;
}

.home__faq {
    padding: 35px 25px;
}
.faq__texts p:nth-child(1) {
    font-weight: 700;
    font-size: 15pt;
    color: #FF6900;
    padding-bottom: 10px;
}
.faq__texts p:nth-child(2), .faq__texts p:nth-child(4), 
.faq__texts p:nth-child(6) {
    font-weight: 700;
    font-size: 12pt;
    color: #818181;
    padding-bottom: 5px;
}
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS PAGINA CADASTRO                                             */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.cad__area {
    padding: 35px 25px;
}
.cad__title {
    font-weight: 700;
    font-size: 15pt !important;
    color: #FF6900 !important;
    padding-bottom: 20px !important;
}
.cad__success_gif  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.cad__success_gif img {
    width: 50%;
}
.cad__form_p_error {
    font-weight: 700;
    font-size: 12pt;
    color: #ff0000 !important;
    padding: 2px 0;
}
.cad__form_p {
    font-weight: 700;
    font-size: 12pt;
    color: #646464;
    padding: 2px 0;
}
.cad__form_input_error {
    width: 100%;
    height: 26px;
    border-radius: 8px;
    border: 1px solid #707070;
    background-color: #F8F8F8;
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 10px;
    font-size: 12pt;
    border: 1px solid #ff0000 !important;
}
.cad__form_input {
    width: 100%;
    height: 26px;
    border-radius: 8px;
    border: 1px solid #707070;
    background-color: #F8F8F8;
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 10px;
    font-size: 12pt;
}
.cad__form_input_check {
    height: 15px;
    border-radius: 8px;
    border: 1px solid #707070;
    background-color: #F8F8F8;
    font-size: 12pt;
    margin: 0 10px;
}
.form__both_in_a_line {
    display: flex;
    justify-content: space-between;
}
.form__show_address_fields {
    animation: slide-in 0.5s cubic-bezier(
        0.250, 0.460, 0.450, 0.940
    ) both;
}
.form__check_term {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;
}
.form__check_term a {
    margin-left: 5px;
    text-decoration: underline;
    color: #FF6900;
}
.form__bil-div-23 {
    width: 23%;
}
.form__bil-div-48 {
    width: 48%;
}
.form__bil-div-73 {
    width: 73%;
}
.form__title_flag {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form__title_flag img {
    width: 18%;
    margin-right: 10px;
}
.form__find-cep {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.form__find-cep a {
    font-weight: 700;
    font-size: 12pt;
    color: #FF6900;
    padding: 3px 10px;
    border: 1px solid #FF6900;
    border-radius: 10px;
    transition: all .3s;
}
.form__find-cep a:hover {
    color: #FFF;
    background-color: #FF6900;
}
.form__find-cep a:active {
    color: #FFF;
    background-color: #ad4800;
    border: 1px solid #ad4800;
}
.cad__area_button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cad__area_button button {
    width: 100%;
    height: 40px;
    background-color: #FF6900;
    color: #FFF;
    border-radius: 8px;
    border: 0;
    font-weight: 700;
    font-size: 12pt;
    transition: all .3s;
}
.cad__area_button button:active {
    background-color: #ffcaa4;
    color: #ffffff;
}
.cad__paymnt_twin_text {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.cad__paymnt_twin_text p:nth-child(2) {
    font-size: 8pt;
}
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS PAGINA TERMO                                                */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.term__area, .polit__area {
    padding: 35px 25px;
    text-align: justify;
    text-justify: inter-word;
}
.term__area p, .polit__area p {
    padding: 10px 0;
    color: #707070;
}
.term__area div, .polit__area div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.term__title, .polit__title {
    font-weight: 700;
    font-size: 15pt;
    color: #FF6900;
    padding-bottom: 20px;
}
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS PAGINA CONTATO                                              */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/* .contact__area {
    padding: 35px 25px;
    text-align: justify;
    text-justify: inter-word;
}
.contact__title {
    font-weight: 700;
    font-size: 15pt;
    color: #FF6900;
    padding-bottom: 20px;
}
.contact__form p {
    font-weight: 700;
    font-size: 12pt;
    color: #646464;
    padding: 2px 0;
}
.contact__form_p_detail {
    font-weight: 400 !important;
    font-size: 10pt !important;
    text-align: center;
}
.contact__form input {
    width: 100%;
    height: 26px;
    border-radius: 8px;
    border: 1px solid #707070;
    background-color: #F8F8F8;
    margin-bottom: 23px;
    padding-left: 10px;
}
.contact__form textarea {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    border: 1px solid #707070;
    background-color: #F8F8F8;
    margin-bottom: 23px;
    padding: 10px;
    resize: none;
}
.contact__area_button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact__area_button button {
    width: 100%;
    height: 26px;
    background-color: #FF6900;
    color: #FFF;
    border-radius: 8px;
    border: 0;
    font-weight: 700;
    font-size: 12pt;
    transition: all .3s;
}
.contact__area_button button:active {
    background-color: #ffcaa4;
    color: #ffffff;
} */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
/*  CSS COMPONENTE FOOTER                                           */
/*                                                                  */
/*------------------------------------------------------------------*/
/*                                                                  */
.footer__area {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 10%;
}
.footer__logo {
    padding: 30px 0;
}
.footer__texts {
    color: #656565;
    padding-bottom: 30px;
}
/* CASO RETORNE O BOTÃO 'FALE COM A GENTE' DESCOMENTAR OS CSS ABAIXOS */
/* .footer__texts p:nth-child(1), .footer__texts p:nth-child(2), */
/* .footer__texts p:nth-child(3), .footer__texts p:nth-child(4) { */
    /* font-weight: 400; */
/* } */
/* .footer__texts p:nth-child(5), .footer__texts p:nth-child(10), */
/* remover os dois abaixo após corrigir os links */
/* .footer__texts p:nth-child(9), */
/* remover os dois acima após corrigir os links */
/* .footer__texts a p { */
    /* font-weight: 700 !important; */
    /* padding: 2.5px 0 !important; */
/* } */
/* .footer__texts p:nth-child(1), .footer__texts p:nth-child(2), */
/* .footer__texts p:nth-child(3), */
/* remover os dois abaixo após corrigir os links */
/* .footer__texts p:nth-child(8), */
/* remover os dois acima após corrigir os links */
/* .footer__texts a p { */
    /* font-size: 10pt; */
/* } */
/* .footer__texts p:nth-child(4), .footer__texts p:nth-child(9) { */
    /* font-size: 15pt; */
    /* padding: 4px 0; */
/* } */
/* .footer__texts p:nth-child(1), .footer__texts p:nth-child(3) { */
    /* padding: 8px 0; */
/* } */
/* remover os dois abaixo após corrigir os links */
/* .footer__texts p:nth-child(8) { */
/* remover os dois acima após corrigir os links */
    /* cursor: pointer; */
/* } */

/* CASO RETORNE O BOTÃO 'FALE COM A GENTE' REMOVER OS CSS ABAIXOS */
.footer__texts p:nth-child(1), .footer__texts p:nth-child(2),
.footer__texts p:nth-child(3) {
    font-weight: 400;
}
.footer__texts p:nth-child(4), .footer__texts p:nth-child(9),
.footer__texts p:nth-child(8),
.footer__texts a p {
    font-weight: 700 !important;
    padding: 2.5px 0 !important;
}
.footer__texts p:nth-child(1), .footer__texts p:nth-child(2),
.footer__texts p:nth-child(3),
.footer__texts p:nth-child(8),
.footer__texts a p {
    font-size: 10pt;
}
.footer__texts p:nth-child(4), .footer__texts p:nth-child(9) {
    font-size: 15pt;
    padding: 4px 0;
}
.footer__texts p:nth-child(1), .footer__texts p:nth-child(3) {
    padding: 8px 0;
}
.footer__texts p:nth-child(8) {
    cursor: pointer;
}
/*                                                                  */
/*==================================================================*/